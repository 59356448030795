<template>
  <div class="layout-wrapper">
    <side-bar />

    <div class="content">
      <user-header-mobile />

      <user-header-desktop />

      <div class="slot-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLayoutUser",
  components: {
    SideBar: () => import("@/components/SideBar.vue"),
    UserHeaderMobile: () => import("@/components/UserHeaderMobile.vue"),
    UserHeaderDesktop: () => import("@/components/UserHeaderDesktop.vue"),
  },
};
</script>

<style scoped lang="scss">
.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 0;

  .content {
    flex-grow: 1;
    background: #f5f7fb;
  }

  .slot-content {
    padding: 2rem 1rem;
  }
}

@media only screen and (min-width: 769px) {
  .slot-content {
    padding: 2rem !important;
  }
}

.mbh {
  .layout-wrapper {
    .content {
      background: #f5f5f5;
    }
  }
}
</style>
